import React, { useState } from "react";
import HamburgerIcon from "./assets/images/Hamburger.svg";
import CloseIcon from "./assets/images/Close.svg";
import notificationBell from "./assets/images/notification-bell.svg";
import logo from "./assets/images/logo-full-new.png";
import ProfilePopover from "./assets/images/ProfilePopover.svg";
import "./assets/style.scss";
import SquaresFour from "./assets/images/SquaresFour.svg";
import Like from "./assets/images/wishlist.svg";
import SignOut from "./assets/images/SignOut.svg";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import useScreenSize from "../../hooks/useScreenSize";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGeoLocation, logout } from "../../config/commonAction";
import { Link, useNavigate } from "react-router-dom";
import { getUser } from "./action";
import Learnings from "../Sidebar/assets/BookOpen.svg";
import Calendar from "../Sidebar/assets/Calendar.svg";
import Purchases from "../Sidebar/assets/ClockCounterClockwise.svg";
import GearSix from "./assets/images/GearSix.svg";
import websiteNavigation from "./assets/images/websiteNavigation.svg";
import getRedirectUrl from "../../utils/getRedirectUrl";
import { toast } from "react-hot-toast";
const style = {
  position: "absolute",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: "none",
  maxHeight: "95vh",
  outline: "none",
  border: "none",
  right: "-190px",
  top: "310px",
  overflowY: "scroll",
  borderRadius: "10px",
};

const responsiveStyle = {
  position: "absolute",
  top: "334px",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  p: 2,
  overflow: "scroll",
  outline: "none",
  border: "none",
};

/* eslint-disable */

const Navbar = () => {
  const isMobile = useScreenSize();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const navigate = useNavigate();
  const path = window.location.pathname.split("/")[1];

  useEffect(() => {
    dispatch(getGeoLocation());
    dispatch(getUser());

    const handleBeforeUnload = () => {
      localStorage.removeItem("currentGeoLocation");
    };

    // adding the beforeunload event listener

    window.addEventListener("beforeunload", handleBeforeUnload);

    // it Clean up the event listener on component unmount

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  const handleNavigate = (link) => {
    navigate(`/${link}`);
    setOpenModal(false);
  };

  const applyJob = () => {
    const url = getRedirectUrl({ type: "CANDIDATE_LOGIN", role: "CANDIDATE" });
    toast.success("Taking you to your Dream Job!");
    window.open(url);
  };

  const { userDetail } = useSelector((state) => state.authState) || {};
  const { user } = userDetail || {};
  const { first_name, last_name, email, profile_image } = user || {};

  return (
    <nav className="main-nav">
      <div className="main-nav-flex">
        <div>
          <Link to="/dashboard">
            <img
              className="main-nav-logo"
              src={logo}
              alt="MentorKart"
              title="MentorKart"
            />
          </Link>
        </div>
        {path !== "moderator" ? (
          <div className="links-wrapper">
            <ul>
              <li>
                <Link to="/mentor-list">
                  <p>Find a Mentor</p>
                </Link>
              </li>
              <li>
                <Link to="/packages-list">
                  <p>Goal Based Mentorship</p>
                </Link>
              </li>
              <li>
                <Link to="/courses-list">
                  <p>Programs & Courses</p>
                </Link>
              </li>
              <li>
                <Link to="/job-guarantee-program">
                  <p>Job Ready Programs</p>
                </Link>
              </li>
              <li>
                <Link to="/live-sessions">
                  <p>
                    Live Sessions
                    <br />
                    <small style={{ fontSize: "10px" }}>
                      (With Certificate)
                    </small>
                  </p>
                </Link>
              </li>
              {/* <li>
            <Link to='#'>Community</Link>
          </li> */}
            </ul>
          </div>
        ) : (
          ""
        )}

        {path === "moderator" ? (
          <h1 className="admin-moderator">Admin Moderator</h1>
        ) : (
          ""
        )}

        <div className="main-nav-notification-popup">
          {path == "moderator" ? (
            ""
          ) : (
            <div className="notification-bell-div">
              <img alt="sidebar-logo" src={notificationBell} />
              {/*<Modal*/}
              {/*    open={openModal}*/}
              {/*    onClose={handleCloseModal}*/}
              {/*    aria-labelledby="modal-modal-title"*/}
              {/*    aria-describedby="modal-modal-description"*/}
              {/*>*/}
              {/*    <Box className="overflow-notification-box" sx={isMobile ? responsiveStyle : style}>*/}
              {/*        <div>*/}
              {/*            <div className="c-h-notification">*/}
              {/*                <div>*/}
              {/*                    <h1>Notification</h1>*/}
              {/*                </div>*/}
              {/*                <div>*/}
              {/*                    <p>Mark as read</p>*/}
              {/*                </div>*/}
              {/*            </div>*/}
              {/*            <div className="c-h-notification-msg">*/}
              {/*                <div className="c-h-notification-msg-flex">*/}
              {/*                    <div>*/}
              {/*                        <img src={Avtar} alt="user" />*/}
              {/*                    </div>*/}
              {/*                    <div>*/}
              {/*                        <h2>Brooklyn Simmons</h2>*/}
              {/*                        <p>Very happy for you</p>*/}
              {/*                    </div>*/}
              {/*                </div>*/}
              {/*                <div>*/}
              {/*                    <h3>13:09</h3>*/}
              {/*                    {*/}
              {/*                        readAll ? <div className="c-h-notification-box">*/}
              {/*                            <p>1</p>*/}
              {/*                        </div>*/}
              {/*                            : ""*/}
              {/*                    }*/}

              {/*                </div>*/}
              {/*            </div>*/}
              {/*            <hr className="msg-row" />*/}
              {/*            <div className="c-h-notification-msg">*/}
              {/*                <div className="c-h-notification-msg-flex">*/}
              {/*                    <div>*/}
              {/*                        <img src={Avtar} alt="user" />*/}
              {/*                    </div>*/}
              {/*                    <div>*/}
              {/*                        <h2>Brooklyn Simmons</h2>*/}
              {/*                        <p>Very happy for you</p>*/}
              {/*                    </div>*/}
              {/*                </div>*/}
              {/*                <div>*/}
              {/*                    <h3>13:09</h3>*/}
              {/*                    {*/}
              {/*                        readAll ? <div className="c-h-notification-box">*/}
              {/*                            <p>1</p>*/}
              {/*                        </div>*/}
              {/*                            : ""*/}
              {/*                    }*/}

              {/*                </div>*/}
              {/*            </div>*/}
              {/*            <hr className="msg-row" />*/}
              {/*            <div className="c-h-notification-msg">*/}
              {/*                <div className="c-h-notification-msg-flex">*/}
              {/*                    <div>*/}
              {/*                        <img src={Avtar} alt="user" />*/}
              {/*                    </div>*/}
              {/*                    <div>*/}
              {/*                        <h2>Brooklyn Simmons</h2>*/}
              {/*                        <p>Very happy for you</p>*/}
              {/*                    </div>*/}
              {/*                </div>*/}
              {/*                <div>*/}
              {/*                    <h3>13:09</h3>*/}
              {/*                    {*/}
              {/*                        readAll ? <div className="c-h-notification-box">*/}
              {/*                            <p>1</p>*/}
              {/*                        </div>*/}
              {/*                            : ""*/}
              {/*                    }*/}

              {/*                </div>*/}
              {/*            </div>*/}
              {/*            <hr className="msg-row" />*/}
              {/*            <div className="c-h-notification-msg">*/}
              {/*                <div className="c-h-notification-msg-flex">*/}
              {/*                    <div>*/}
              {/*                        <img src={Avtar} alt="user" />*/}
              {/*                    </div>*/}
              {/*                    <div>*/}
              {/*                        <h2>Brooklyn Simmons</h2>*/}
              {/*                        <p>Very happy for you</p>*/}
              {/*                    </div>*/}
              {/*                </div>*/}
              {/*                <div>*/}
              {/*                    <h3>13:09</h3>*/}
              {/*                    {*/}
              {/*                        readAll ? <div className="c-h-notification-box">*/}
              {/*                            <p>1</p>*/}
              {/*                        </div>*/}
              {/*                            : ""*/}
              {/*                    }*/}

              {/*                </div>*/}
              {/*            </div>*/}
              {/*            <hr className="msg-row" />*/}
              {/*            <div className="c-h-notification-msg">*/}
              {/*                <div className="c-h-notification-msg-flex">*/}
              {/*                    <div>*/}
              {/*                        <img src={Avtar} alt="user" />*/}
              {/*                    </div>*/}
              {/*                    <div>*/}
              {/*                        <h2>Brooklyn Simmons</h2>*/}
              {/*                        <p>Very happy for you</p>*/}
              {/*                    </div>*/}
              {/*                </div>*/}
              {/*                <div>*/}
              {/*                    <h3>13:09</h3>*/}
              {/*                    {*/}
              {/*                        !readAll ? <div className="c-h-notification-box">*/}
              {/*                            <p>1</p>*/}
              {/*                        </div>*/}
              {/*                            : ""*/}
              {/*                    }*/}
              {/*                </div>*/}
              {/*            </div>*/}
              {/*            <hr className="msg-row" />*/}
              {/*        </div>*/}
              {/*    </Box>*/}
              {/*</Modal>*/}
            </div>
          )}
          {path == "moderator" ? (
            <button onClick={handleLogout} className="moderator-logout-button">
              LogOut
            </button>
          ) : (
            ""
          )}

          {path !== "moderator" ? (
            <>
              <div
                onClick={handleOpenModal}
                className="main-nav-user-profile-popover"
              >
                {profile_image ? (
                  <img
                    src={profile_image}
                    alt="Profile image"
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <h1>
                    {first_name?.split("").shift().toUpperCase()}
                    {last_name?.split("").shift().toUpperCase()}
                  </h1>
                )}
              </div>
              <div onClick={handleOpenModal}>
                <img alt="sidebar-logo" src={ProfilePopover} />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            className="popover-modal"
            sx={isMobile ? responsiveStyle : style}
          >
            <div className="main-nav-popover-box">
              <div className="main-nav-inner-popover">
                <div className="main-nav-user-details">
                  <div className="main-nav-user-inner-popover">
                    {profile_image ? (
                      <img
                        src={profile_image}
                        alt="Profile image"
                        style={{
                          width: "inherit",
                          height: "inherit",
                          borderRadius: "50px",
                          marginTop: "-10px",
                        }}
                      />
                    ) : (
                      <h1 className="main-nav-user-name-popover">
                        {first_name?.split("").shift().toUpperCase()}
                        {last_name?.split("").shift().toUpperCase()}
                      </h1>
                    )}
                  </div>
                  <div
                    onClick={() => handleNavigate("profile")}
                    style={{ cursor: "pointer" }}
                  >
                    <h1 className="main-nav-user">
                      {first_name?.charAt(0).toUpperCase() +
                        first_name?.slice(1).toLowerCase()}{" "}
                      {last_name
                        ? last_name?.charAt(0).toUpperCase() +
                          last_name?.slice(1).toLowerCase()
                        : ""}
                    </h1>
                    {path == "moderator" ? (
                      ""
                    ) : (
                      <p className="main-user-view-profile">View Profile</p>
                    )}
                  </div>
                </div>
                <hr />
                {path !== "moderator" ? (
                  <>
                    <div
                      className="main-nav-user-details-dropdown"
                      onClick={() => handleNavigate("dashboard")}
                    >
                      <div>
                        <img src={SquaresFour} alt="sidebar-logo" />
                      </div>
                      <div>
                        <h1 className="main-nav-user">My Dashboard</h1>
                        <p className="main-user-view-profile">
                          Manage your customized dashboard here
                        </p>
                      </div>
                    </div>
                    <div
                      className="main-nav-user-details-dropdown"
                      onClick={() => handleNavigate("my-learning")}
                    >
                      <div>
                        <img
                          src={Learnings}
                          alt="sidebar-logo"
                          style={{ width: "32px" }}
                        />
                      </div>
                      <div>
                        <h1 className="main-nav-user">My Learnings</h1>
                        <p className="main-user-view-profile">
                          All the programs and courses you enroll will be shown
                          here
                        </p>
                      </div>
                    </div>
                    <div
                      className="main-nav-user-details-dropdown"
                      onClick={() => handleNavigate("wishlist")}
                    >
                      <div>
                        <img src={Like} alt="sidebar-logo" />
                      </div>
                      <div>
                        <h1 className="main-nav-user">Wishlist</h1>
                        <p className="main-user-view-profile">
                          View all your liked courses, mentors and programs
                        </p>
                      </div>
                    </div>

                    <div
                      className="main-nav-user-details-dropdown"
                      onClick={() => handleNavigate("appointments")}
                    >
                      <div>
                        <img
                          src={Calendar}
                          alt="sidebar-logo"
                          style={{ width: "32px" }}
                        />
                      </div>
                      <div>
                        <h1 className="main-nav-user">Appointments</h1>
                        <p className="main-user-view-profile">
                          Video call or Chat with mentors directly
                        </p>
                      </div>
                    </div>
                    <div
                      className="main-nav-user-details-dropdown"
                      onClick={() => handleNavigate("order-history")}
                    >
                      <div>
                        <img
                          src={Purchases}
                          alt="sidebar-logo"
                          style={{ width: "32px" }}
                        />
                      </div>
                      <div>
                        <h1 className="main-nav-user">My Purchases</h1>
                        <p className="main-user-view-profile">
                          See what you've purchased from us.
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div
                      className="main-nav-user-details-dropdown"
                      onClick={() => handleNavigate("setting")}
                    >
                      <div>
                        <img src={GearSix} alt="sidebar-logo" />
                      </div>
                      <div>
                        <h1 className="main-nav-user">Settings</h1>
                      </div>
                    </div>
                    <a href={process.env.REACT_APP_WEBSITE_URL}>
                      <div className="main-nav-user-details-dropdown">
                        <div>
                          <img src={websiteNavigation} alt="sidebar-logo" />
                        </div>
                        <div>
                          <h1 className="main-nav-user">Back to Website</h1>
                        </div>
                      </div>
                    </a>
                  </>
                ) : (
                  ""
                )}

                <div className="main-nav-user-details-dropdown">
                  <div>
                    <img src={SignOut} alt="sidebar-logo" />
                  </div>
                  <div onClick={handleLogout}>
                    <h1 className="main-nav-user">LogOut</h1>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      {path === "moderator" ? (
        ""
      ) : (
        <div>
          {open ? (
            <div
              className="nav-links-menu-btn close"
              onClick={() => {
                setOpen(false);
              }}
            >
              <img src={CloseIcon} alt="sidebar-logo" />
            </div>
          ) : (
            <div
              className="nav-links-menu-btn"
              onClick={() => {
                setOpen(true);
              }}
            >
              <img src={HamburgerIcon} alt="sidebar-logo" />
            </div>
          )}
          {open && (
            <div className="sm-links-wrapper links-wrapper">
              <a href="/mentor-list">Find a Mentor</a>
              <a href="/packages-list">Goal Based Mentorship</a>
              <a href="/courses-list">Program & Courses</a>
              <a href="/live-sessions">
                Live Sessions
                <small style={{ fontSize: "10px", marginLeft: "5px" }}>
                  {" "}
                  (With Certificate)
                </small>
              </a>
              <a href="/job-guarantee-program">Job Ready Programs</a>
              <a onClick={applyJob}>Dream Jobs</a>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
