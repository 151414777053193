/*eslint-disable */
import React, { Suspense, lazy } from "react";
import "./App.scss";
import Navbar from "./Component/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import JobGuaranteeDetails from "./Component/JobGuaranteeDetails";
import { BounceLoader } from "react-spinners";
import PrivateRoute from "./PrivateRoute";
import ModeratorRoute from "./ModeratorRoute";
import MentorRoute from "./MentorRoute";
import { Toaster } from "react-hot-toast";
import CommonRoute from "./CommonRoute";
const GlobalSearch = lazy(() => import("./Component/GlobalSearch"));
const ConfirmOrder = lazy(() => import("./Component/ConfirmOrder"));
const Mentors = lazy(() => import("./pages/Mentors"));
const Moderator = lazy(() => import("./pages/Moderator"));
const Courses = lazy(() => import("./pages/Courses"));
const Packages = lazy(() => import("./pages/Packages"));
const UpcomingSessions = lazy(() => import("./pages/UpcomingSessions"));
const jobGuaranteeSessions = lazy(() => import("./pages/JobGuaranteeSessions"));
const Signup = lazy(() => import("./pages/Signup"));
const Sidebar = lazy(() => import("./Component/Sidebar"));
const MentorInfo = lazy(() => import("./pages/MentorInfo"));
const packageInfo = lazy(() => import("./pages/PackageInfo"));
const CourseInfo = lazy(() => import("./pages/CourseInfo"));
const PaymentOK = lazy(() => import("./pages/PaymentSuccess"));
const PaymentFail = lazy(() => import("./pages/PurchaseFailed"));
const AicteHome = lazy(() => import ("./pages/AicteCourse"));
const AictePaymentOK = lazy(() => import("./pages/PaymentSuccess"));
const AictePaymentFailed = lazy(() => import("./pages/PurchaseFailed"));
const MyLearning = lazy(() => import("./Component/MyLearning"));
const MyAppointments = lazy(() => import("./Component/Appointments"));
const MyPurchases = lazy(() => import("./Component/MyPurchases"));
const WishList = lazy(() => import("./Component/WishList"));
const GamesDashboard = lazy(() => import("./pages/Games"));
const Profile = lazy(() => import("./pages/Profile/index"));
const ResumeDownload = lazy(() => import("./pages/Profile/ExportPdfComponent"));
const MentorDashboard = lazy(() => import("./pages/MentorAppDashboard"));
const Setting = lazy(() => import("./Component/Setting"));
const Lobby = lazy(() => import("./pages/VideoCall/components/Lobby"));
const Meeting = lazy(() => import("./pages/VideoCall/components/Meeting"));
const Global = lazy(() => import("./pages/Deeplinks"));
const Partners = lazy(() => import("./pages/Partners"));
const SSOAuth = lazy(() => import("./pages/SSOAuth"));
const PackageDetails = lazy(() => import("./Component/PackageDetails"));
const TermsAndCondition = lazy(() => import("./pages/TermsAndCondition/index"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyAndPolicy/index"));
const VideoResourses = lazy(() => import("./Component/VideoResourses"));
const JobGuaranteeEnroll = lazy(() => import("./pages/JobGuaranteeEnroll"));
const Registration = lazy(() =>
  import("./pages/JobGuaranteeEnroll/Registration")
);

function App() {
  const routes = [
    "games-dashboard",
    "confirm-order",
    "profile",
    "download-cv",
    "mentor",
    "lobby",
    "meeting-room",
    "mentorkart",
    "partners",
    "terms-and-conditions",
    "privacy-policy",
    "social",
    "feedback",
    "meet",
    "auth",
    "moderator",
    "aicte"
  ];
  const LazyRoute = ({ component: Component, ...rest }) => {
    const path = window.location.pathname.split("/")[1];
    const endpoint = window.location.pathname.split("/")[3] || "";
    return (
      <Suspense
        fallback={
          <div
            style={{
              height: "100vh",
              width: "100vw",
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BounceLoader size={80} color="#0f1113" />
          </div>
        }
      >
        <Toaster gutter={10000} position="top-center" />
        {path !== "" &&
          path !== "mentor" &&
          path !== "lobby" &&
          path !== "meeting-room" &&
          path !== "partners" &&
          path !== "mentorkart" &&
          path !== "social" &&
          path !== "privacy-policy" &&
          path !== "terms-and-conditions" &&
          path !== "feedback" &&
          path !== "meet" &&
          path !== "aicte" &&
          path !== "auth" && <Navbar />}
        {!routes.includes(path) && !routes.includes(endpoint) && path !== "" ? (
          <Sidebar />
        ) : (
          <></>
        )}

        <Component {...rest} />
      </Suspense>
    );
  };
  return (
    <div className="app">
      <BrowserRouter>
        <Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                width: "100vw",
                position: "fixed",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BounceLoader size={80} color="#0f1113" />
            </div>
          }
        ></Suspense>
        <Routes>
          <Route path="/" element={<LazyRoute component={Signup} />} />
          <Route
            path="/social/authenticated/:channel"
            element={<LazyRoute component={Signup} />}
          />
          <Route
            exact
            path="/mentor-list"
            element={
              <PrivateRoute>
                <LazyRoute component={Mentors} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/moderator"
            element={
              <ModeratorRoute>
                <LazyRoute component={Moderator} />
              </ModeratorRoute>
            }
          />
          <Route
            exact
            path="/mentor-list/:mentorId"
            element={
              <PrivateRoute>
                <LazyRoute component={MentorInfo} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/courses-list"
            element={
              <PrivateRoute>
                <LazyRoute component={Courses} />
              </PrivateRoute>
            }
          />
          <Route 
            exact
            path="/aicte/course"
            element={
              <LazyRoute component={AicteHome} />
            }
          />
          <Route
            exact
            path="/courses-list/:courseId"
            element={
              <PrivateRoute>
                <LazyRoute component={CourseInfo} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/packages-list"
            element={
              <PrivateRoute>
                <LazyRoute component={Packages} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/packages-list/:packageId"
            element={
              <PrivateRoute>
                <LazyRoute component={packageInfo} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/packages-list/:packageId/mentor-list/:mentorId"
            element={
              <PrivateRoute>
                <LazyRoute component={MentorInfo} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/package-details/:packageId/mentor-list/:mentorId"
            element={
              <PrivateRoute>
                <LazyRoute component={MentorInfo} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/packages-list/:packageId/courses-list/:mentorId"
            element={
              <PrivateRoute>
                <LazyRoute component={CourseInfo} />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/package-details/:packageId/courses-list/:mentorId"
            element={
              <PrivateRoute>
                <LazyRoute component={CourseInfo} />
              </PrivateRoute>
            }
          />
          <Route
            path="/live-sessions"
            element={
              <PrivateRoute>
                <LazyRoute component={UpcomingSessions} />
              </PrivateRoute>
            }
          />
          <Route
            path="/live-sessions/:Id"
            element={
              <PrivateRoute>
                <LazyRoute component={CourseInfo} />
              </PrivateRoute>
            }
          />
          <Route
            path="/job-guarantee-program"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={jobGuaranteeSessions} />
              </PrivateRoute>
            }
          />
          <Route
            path="/job-guarantee-program/:batchid/:id"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={CourseInfo} />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={GlobalSearch} />
              </PrivateRoute>
            }
          />
          <Route
            path="/My-Learning"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={MyLearning} />
              </PrivateRoute>
            }
          />
          <Route
            path="/appointments"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={MyAppointments} />
              </PrivateRoute>
            }
          />
          <Route
            path="/order-history"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={MyPurchases} />
              </PrivateRoute>
            }
          />
          <Route
            path="/order-history/:id"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={MyPurchases} />
              </PrivateRoute>
            }
          />
          <Route
            path="/wishlist"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={WishList} />
              </PrivateRoute>
            }
          />
          <Route
            path="/payment/success"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={PaymentOK} />
              </PrivateRoute>
            }
          />
          <Route
            path="/payment/failed"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={PaymentFail} />
              </PrivateRoute>
            }
          />
          <Route
            path="/aicte/course/payment/success"
            exact
            element={
                <LazyRoute component={AictePaymentOK} />
            }
          />
          <Route
            path="/aicte/course/payment/failed"
            exact
            element={
                <LazyRoute component={AictePaymentFailed} />
            }
          />
          <Route
            path="/confirm-order"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={ConfirmOrder} />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={Profile} />
              </PrivateRoute>
            }
          />
          <Route
            path="/download-cv"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={ResumeDownload} />
              </PrivateRoute>
            }
          />
          <Route
            path="/job-guarantee"
            exact
            element={<JobGuaranteeDetails />}
          />
          <Route
            path="/games-dashboard"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={GamesDashboard} />
              </PrivateRoute>
            }
          />
          <Route
            path="/mentor"
            exact
            element={
              <MentorRoute>
                <LazyRoute component={MentorDashboard} />
              </MentorRoute>
            }
          />
          <Route
            path="/package-details/:id"
            exact
            element={
              <PrivateRoute>
                <LazyRoute component={PackageDetails} />
              </PrivateRoute>
            }
          />
          <Route path="/meet/:id" exact element={
            <CommonRoute>
              <LazyRoute component={Lobby} />
            </CommonRoute>
          } />
          <Route path="/meeting-room/:id" exact element={
            <CommonRoute>
              <LazyRoute component={Meeting} />
            </CommonRoute>
          } />
          <Route path="/job-guarantee-enroll/:id" exact element={
            <PrivateRoute>
              <LazyRoute component={JobGuaranteeEnroll} />
            </PrivateRoute>
          } />
          <Route path="/job-guarantee-enroll/:id/registration-page" exact element={
            <PrivateRoute>
              <LazyRoute component={Registration} />
            </PrivateRoute>
          } />
          <Route path="/mentor-talks" exact element={
            <PrivateRoute>
              <LazyRoute component={VideoResourses} />
            </PrivateRoute>
          } />
          <Route path="/setting" exact element={<LazyRoute component={Setting} />} />
          <Route path="/mentorkart" exact element={<LazyRoute component={Global} />} />
          <Route path="/partners" exact element={<LazyRoute component={Partners} />} />
          <Route path="/auth" exact element={<LazyRoute component={SSOAuth} />} />
          <Route path="/terms-and-conditions" exact element={<LazyRoute component={TermsAndCondition} />} />
          <Route path="/privacy-policy" exact element={<LazyRoute component={PrivacyPolicy} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
